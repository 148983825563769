import { FC, memo, useState } from 'react';
import s from './AppHeader.module.scss';
import { Burger } from '@/shared/UI/Burger/Burger';
import { Menu } from '@/widgets/AppHeader/ui/Menu/Menu';
import { useWindowSize } from 'usehooks-ts';
import { isDesktopWidth } from '@/shared/const/reponsive.const';
import {Link, useLocation} from 'react-router-dom';
import { router } from '@/app/router/const/router';
import { menu } from '@/widgets/AppHeader/model/const/menu';
import { SocialList } from '@/entities/Social';
import {Logo} from "@/shared/UI/Logo/Logo";

interface PropsTypes {}

export const AppHeader: FC<PropsTypes> = memo(() => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const location = useLocation();
  const { width: windowWidth } = useWindowSize();
  const isDesktop = isDesktopWidth(windowWidth);
  return (
    <>
      <div className={s.AppHeader}>
        <div className="container">
          <div className={s.wrapper}>
            {/*<div className={s.left}>*/}
            <Link className={s.logoLink} to={router.main()}>
              <Logo />
            </Link>
            {/*</div>*/}
            {/*<div className={s.title}>*/}
            {/*  Mis <br /> Zhytomyr*/}
            {/*</div>*/}
            {/*<div className={s.right}>*/}
            {isDesktop ? (
              <>
                <ul className={s.menuList}>
                  {menu.map(menuItem => (
                    <li key={menuItem.value} className={s.item} value={menuItem.value}>
                      {(menuItem.route.includes(location.pathname) && menuItem.route.includes('#')) ? (
                          <a href={menuItem.route} className={s.link}>
                            {menuItem.name}
                          </a>
                      ) : (
                          <Link to={menuItem.route} className={s.link}>
                            {menuItem.name}
                          </Link>
                      )}
                    </li>
                  ))}
                </ul>
                <SocialList />
              </>
            ) : (
              <Burger onClick={() => setIsMenuOpened(!isMenuOpened)} />
            )}
            {/*  {isTablet && <Button className={s.btn} href={router.votes()}>проголосувати</Button>}*/}
            {/*</div>*/}
          </div>
        </div>
      </div>

      {isMenuOpened && <Menu onClose={() => setIsMenuOpened(false)} />}
    </>
  );
});
