import { FC, memo } from "react";
import s from './Logo.module.scss'
import { Logo as LogoSvg } from '@/shared/assets';
import classNames from "classnames";

interface PropsTypes {
    withTitle?: boolean
    direction?: 'col' | 'row'
    size?: 's' | 'm' | 'l'
}
export const Logo: FC<PropsTypes> = memo(({withTitle, size = 'm', direction = 'row'}) => {
  return (
    <div className={classNames(s.Logo, s[direction], s[size])}>
        <LogoSvg className={classNames(s.logo)}/>
        {withTitle &&
            <div className={s.logoText}>Mis Zhytomyr</div>
        }
    </div>
  );
});