
export const RESPONSIVE_MOBILE_LANDSCAPE_WIDTH = 480;
export const RESPONSIVE_TABLET_WIDTH = 768;
export const RESPONSIVE_LAPTOP_WIDTH = 992;
export const RESPONSIVE_DESKTOP_WIDTH = 1200;

// export const MOBILE_LANDSCAPE_MEDIA_QUERY = `(width >= ${RESPONSIVE_MOBILE_LANDSCAPE_WIDTH}px)`
// export const TABLET_MEDIA_QUERY = `(width >= ${RESPONSIVE_TABLET_WIDTH}px)`
// export const LAPTOP_MEDIA_QUERY = `(width >= ${RESPONSIVE_LAPTOP_WIDTH}px)`
// export const DESKTOP_MEDIA_QUERY = `(width >= ${RESPONSIVE_DESKTOP_WIDTH}px)`


export const isMobileLandscapeWidth = (currentWidth: number) => currentWidth >= RESPONSIVE_MOBILE_LANDSCAPE_WIDTH
export const isTabletWidth = (currentWidth: number) => currentWidth >= RESPONSIVE_TABLET_WIDTH
export const isLaptopWidth = (currentWidth: number) => currentWidth >= RESPONSIVE_LAPTOP_WIDTH
export const isDesktopWidth = (currentWidth: number) => currentWidth >= RESPONSIVE_DESKTOP_WIDTH