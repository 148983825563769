import { FC, memo, ReactNode } from 'react';
import s from './Button.module.scss';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

interface PropsTypes {
  type?: 'button' | 'submit';
  className?: string;
  children: ReactNode;
  filled?: boolean;
  outlined?: boolean;
  variant?: 'default' | 'circle';
  colors?: 'primary' | 'secondary';
  href?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  width?: 'auto' | 'w100' | 'fit';
}

export const Button: FC<PropsTypes> = memo(
  ({
    type = 'button',
    className = '',
    children,
    variant = 'default',
    filled,
    href,
    onClick,
    width,
    colors = 'primary',
  }) => {
    const buildClassNames = classNames(s.Button, className, {
      [s.filled]: filled,
      [s.circle]: variant === 'circle',
      [s[width!]]: width,
      [s[colors]]: colors !== 'primary',
    });

    return (
      <>
        {href ? (
          <Link className={buildClassNames} to={href}>
            {children}
          </Link>
        ) : (
          <button className={buildClassNames} type={type} onClick={onClick}>
            {children}
          </button>
        )}
      </>
    );
  },
);
