import { AppHeader } from '@/widgets/AppHeader';
import s from '@/app/App.module.scss';
import { AppFooter } from '@/widgets/AppFooter';
import { AppRoutes } from '@/app/router';

function App() {
  return (
    <>
      <AppHeader />
      <main className={s.main}>
        <AppRoutes />
      </main>
      <AppFooter />
    </>
  );
}

export default App;
