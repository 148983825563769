import {FC, memo, useRef} from 'react';
import s from './Menu.module.scss';
import { Burger } from '@/shared/UI/Burger/Burger';
import { menu } from '@/widgets/AppHeader/model/const/menu';
import { Button } from '@/shared/UI/Button/Button';
import {useOnClickOutside, useWindowSize} from 'usehooks-ts';
import {isTabletWidth} from '@/shared/const/reponsive.const';
import { router } from '@/app/router/const/router';
import { Link, useLocation } from 'react-router-dom';
import { Logo } from '@/shared/UI/Logo/Logo';

interface PropsTypes {
  onClose: () => void;
}

export const Menu: FC<PropsTypes> = memo(({ onClose }) => {
  const {width: windowWidth} = useWindowSize()
  const isTablet = isTabletWidth(windowWidth);

  const location = useLocation();
  const menuRef = useRef(null)
  useOnClickOutside(menuRef, () => onClose())
  return (
    <div className={s.Menu}>
      <div ref={menuRef} className={s.wrapper} role={'dialog'}>
        <div className={s.header}>
          <Logo withTitle/>
          <Burger onClick={onClose} isOpened={true} />
        </div>
        <div className={s.main}>
          <ul className={s.list}>
            {menu.map(item => (
              <li className={s.item} key={item.value}>
                {(item.route.includes(location.pathname) && item.route.includes('#')) ? (
                  <a href={item.route} className={s.link} onClick={onClose}>
                    {item.name}
                  </a>
                ) : (
                  <Link to={item.route} className={s.link} onClick={onClose}>
                    {item.name}
                  </Link>
                )}
              </li>
            ))}
          </ul>
          {!isTablet && (
            <Button filled className={s.btn} href={router.votes()}>
              Проголосувати
            </Button>
          )}
        </div>
        {isTablet && (
          <div className={s.footer}>
            <div className={s.about}>
              <p>Житомир, вулиця Перемоги, 24</p>
              <p>Концерт-хол “Big room”</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
