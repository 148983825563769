import { FC, memo } from 'react';
import s from './AppFooter.module.scss';
import { menu } from '../../../AppHeader/model/const/menu';
import {useWindowSize} from 'usehooks-ts';
import {isTabletWidth} from '@/shared/const/reponsive.const';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { SocialList } from '@/entities/Social';
import { GeoIcon, PhoneIcon } from '@/shared/assets';
import {Logo} from "@/shared/UI/Logo/Logo";

interface PropsTypes {}

export const AppFooter: FC<PropsTypes> = memo(() => {
  const {width: windowWidth} = useWindowSize()
  const isTablet = isTabletWidth(windowWidth);

  return (
    <footer className={s.AppFooter}>
      <div className="container">
        <div className={s.inner}>
          <div className={classNames(s.logoWrapper)}>
            <Logo withTitle direction={'col'} size={'l'}/>
            <div className={s.logoSubtitle}>Конкурс краси “Міс Житомир”</div>
          </div>
          {isTablet && (
              <div className={classNames(s.block, s.menuWrapper)}>
                <div className={s.blockTitle}>Меню</div>
                <div className={s.menu}>
                  <ul className={s.menuList}>
                    {menu.map(item => (
                        <li key={item.value} className={s.menuItem}>
                          <Link to={item.route} className={s.menuLink}>
                            {item.name}
                          </Link>
                        </li>
                    ))}
                  </ul>
                </div>
              </div>
          )}
          {isTablet && (
              <div className={classNames(s.block, s.locationWrapper)}>
                <div className={s.blockTitle}>Контакти</div>
                <div className={s.itemGroup}>
                  <GeoIcon className={s.itemGroupIcon} />
                  <div className={s.text}>
                    <p>Житомир</p>
                    <p>вулиця Перемоги, 24</p>
                    <p>Концерт-хол “Big room”</p>
                  </div>
                </div>

                <div className={s.itemGroup}>
                  <PhoneIcon className={s.itemGroupIcon} />
                  <div className={s.text}>+380 63 235 55 00</div>
                </div>
              </div>
          )}
          {/*<div className={classNames(s.block, s.dateTimeWrapper)}>*/}
          {/*  <div className={s.blockTitle}>Дата та час</div>*/}
          {/*  <div className={s.blockContent}>*/}
          {/*    <div className={s.text}>15.07.2024</div>*/}
          {/*    <div className={s.text}>12:00</div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={classNames(s.block, s.socialWrapper)}>
            <div className={s.blockTitle}>Ми в соціальних мережах</div>
            <SocialList />
          </div>
        </div>
      </div>
    </footer>
  );
});
